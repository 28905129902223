import React, { useEffect } from "react";
import styles from "./rate.module.css";
import { type } from "@testing-library/user-event/dist/type";



const Rate = ({ duration, rate, active, stakingBalance, stakingBal2, stakingBal3, stakingBal4 }) => {
  const [bal, setBal] = React.useState(0);
  let total = parseInt(stakingBalance) + parseInt(stakingBal2) + parseInt(stakingBal3) + parseInt(stakingBal4);
  useEffect(() => {
    if (active === 0) {
      setBal(stakingBalance);
    } else if (active === 1) {
      setBal(stakingBal2);
    } else if (active === 2) {
      setBal(stakingBal3);
    } else if (active === 3) {
      setBal(stakingBal4);
    }
  }, [bal, active, stakingBalance, stakingBal2, stakingBal3, stakingBal4]);
  return (
    <div className={styles.rateContainer}>
      <div className={styles.spaceBetween}>
        <p className={styles.rate}>Stake Balance (selected Duration)</p>
        <p className={styles.rate}>{Number(bal).toFixed(0)} UNS</p>
      </div>
      <div className={styles.spaceBetween}>
        <p className={styles.rate}>Total Staking Balance</p>
        <p className={styles.rate}>{Number(total ? total : 0).toFixed(0)} UNS</p>
      </div>
      <div className={styles.spaceBetween}>
        <p className={styles.rate}>Estimated Yield</p>
        <p className={styles.rate}>~ {duration[active].apy * bal / 100} UNS</p>
      </div>

      {rate.map((el, i) => (
        <div
          className={[styles.spaceBetween, styles.rateWrapper].join(" ")}
          key={i}
        >
          <p className={styles.rateText}>{el.key}</p>{" "}
          <p className={styles.rateText}>{el.value}</p>
        </div>
      ))}
    </div>
  );
};

export default Rate;
